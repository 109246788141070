import React, { useState } from "react";
import "./evaluation.css";

const TestEvaluator = ({ test, onBack }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [submitted, setSubmitted] = useState(false);

  const currentQuestion = test.questions[currentQuestionIndex];

  const handleAnswerSelection = (answerIndex) => {
    setAnswers({ ...answers, [currentQuestionIndex]: answerIndex });
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < test.questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      setSubmitted(true);
    }
  };

  const calculateScore = () => {
    let score = 0;
    test.questions.forEach((q, index) => {
      if (answers[index] === q.correctAnswer) score++;
    });
    return score;
  };

  return (
    <div className="test-evaluator">
      <h2>{test.title}</h2>

      {!submitted ? (
        <div className="question-block">
          <p className="question-number">
            Pregunta {currentQuestionIndex + 1} de {test.questions.length}
          </p>
          <p className="question-text">{currentQuestion.question}</p>

          {currentQuestion.options.map((option, index) => (
            <label
              key={index}
              className={`option ${
                answers[currentQuestionIndex] === index ? "selected" : ""
              }`}
            >
              <input
                type="radio"
                name={`question-${currentQuestionIndex}`}
                value={index}
                onClick={() => handleAnswerSelection(index)}
              />
              {option}
            </label>
          ))}

          <button
            className="next-button"
            onClick={handleNextQuestion}
            disabled={answers[currentQuestionIndex] === undefined}
          >
            {currentQuestionIndex === test.questions.length - 1
              ? "Finalizar"
              : "Siguiente"}
          </button>
        </div>
      ) : (
        <div className="results">
          <h3>Resultados:</h3>
          <p>
            Puntaje: {calculateScore()} / {test.questions.length}
          </p>
          <button onClick={onBack}>Volver a los Tests</button>
        </div>
      )}
    </div>
  );
};

export default TestEvaluator;
