const testsData = [
  {
    id: 1,
    title: "Módulo 1: Evaluación del Reglamento Atlas Investments",
    description: "Preguntas relacionadas con el reglamento interno de trabajo.",
    questions: [
      {
        question:
          "¿Cuál es el objetivo principal del reglamento interno de trabajo?",
        options: [
          "Regular la relación laboral entre la empresa y sus empleados.",
          "Establecer las normas de comportamiento para los clientes.",
          "Organizar el sistema financiero de la empresa.",
          "Establecer las políticas de seguridad.",
        ],
        correctAnswer: 0,
      },
      {
        question:
          "¿Qué información debe incluirse en el registro de cada trabajador?",
        options: [
          "Nombre, cédula y cargo.",
          "Nombre, dirección y salario.",
          "Dirección, teléfono y experiencia.",
          "Nombre y estado civil.",
        ],
        correctAnswer: 0,
      },
      // Agregar más preguntas...
    ],
  },
  {
    id: 2,
    title: "Módulo 1: Evaluación del Reglamento Atlas Investments",
    description: "Preguntas relacionadas con el reglamento interno de trabajo.",
    questions: [
      {
        question:
          "¿Cuál es el objetivo principal del reglamento interno de trabajo?",
        options: [
          "Regular la relación laboral entre la empresa y sus empleados.",
          "Establecer las normas de comportamiento para los clientes.",
          "Organizar el sistema financiero de la empresa.",
          "Establecer las políticas de seguridad.",
        ],
        correctAnswer: 0,
      },
      {
        question:
          "¿Qué información debe incluirse en el registro de cada trabajador?",
        options: [
          "Nombre, cédula y cargo.",
          "Nombre, dirección y salario.",
          "Dirección, teléfono y experiencia.",
          "Nombre y estado civil.",
        ],
        correctAnswer: 0,
      },
      // Agregar más preguntas...
    ],
  },
];
export default testsData;
