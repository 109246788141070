import React from "react";
//import styles from "./training.module.css";
import TrainingBanner from "./trainingBanner";
import infoBg from "../../assets/img/56757.jpeg";
import Evaluacion from "./testList";

const InfoEvalucion = () => {
  return (
    <div>
      <TrainingBanner backgroundImage={infoBg} title="Knowledge Test" />
      {/* Resto del contenido de la sección */}

      <div>
        <Evaluacion />
      </div>
    </div>
  );
};

export default InfoEvalucion;
