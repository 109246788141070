import React, { useState } from "react";
import TrainingSidebar from "./trainingSidebar";
import InfoGlobal from "./infoGlobal";
import InfoEspecifica from "./infoEspecifica";
import InfoBiblioteca from "./infoBiblioteca";
import InfoEvalucion from "./infoEvaluacion";
import Joyride from "react-joyride"; // Importar Joyride
import styles from "./training.module.css";

const Training = () => {
  const [selectedModule, setSelectedModule] = useState("welcome");
  const [run, setRun] = useState(false);

  // Configuración de pasos para el tour
  const steps = [
    {
      target: "#ssidebar", // Selector del contenedor de la barra lateral
      content:
        "Este es el menú lateral para navegar entre módulos de entrenamiento.",
    },
    {
      target: ".info-global", // Selector para el contenido de información global
      content:
        "Esta sección contiene información global sobre el entrenamiento.",
    },
    {
      target: ".especifica-container", // Selector para la información específica
      content:
        "Aquí puedes explorar información detallada por área específica.",
    },
    {
      target: ".biblioteca", // Selector para la biblioteca
      content:
        "En esta sección encontrarás recursos y documentos relacionados.",
    },
  ];

  const startTour = () => {
    setRun(true);
  };

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = ["finished", "skipped"];
    if (finishedStatuses.includes(status)) {
      setRun(false);
    }
  };
  const renderContent = () => {
    switch (selectedModule) {
      case "informacion-global":
        return (
          <div className="info-global">
            <InfoGlobal />
          </div>
        ); // Agrega la clase para el tour
      case "area-especifica":
        return (
          <div className="area-especifica">
            <InfoEspecifica />
          </div>
        ); // Agrega la clase para el tour
      case "biblioteca":
        return (
          <div className="biblioteca">
            <InfoBiblioteca />
          </div>
        ); // Agrega la clase para el tour
      case "test":
        return (
          <div className="test">
            <InfoEvalucion />
          </div>
        ); // Agrega la clase para el tour
      default:
        return (
          <div className="info-global">
            <InfoGlobal />
          </div>
        );
    }
  };

  return (
    <div className={styles.trainingContainer}>
      <TrainingSidebar
        setSelectedModule={setSelectedModule}
        startTour={startTour}
      />
      <div className={`${styles.content} trainingContent`}>
        {renderContent()}
      </div>
      <Joyride
        steps={steps}
        run={run}
        continuous
        showProgress
        showSkipButton
        callback={handleJoyrideCallback}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      />
      <TrainingSidebar
        setSelectedModule={setSelectedModule}
        startTour={startTour}
      />
    </div>
  );
};

export default Training;
