import React, { useState } from "react";
import testsData from "./testsData";
import TestEvaluator from "./testEvaluator";
import "./evaluation.css";

const TestsList = () => {
  const [selectedTest, setSelectedTest] = useState(null);

  const handleSelectTest = (testId) => {
    const test = testsData.find((t) => t.id === testId);
    setSelectedTest(test);
  };

  return (
    <div>
      {!selectedTest ? (
        <div className="card-container1">
          {testsData.map((test) => (
            <div key={test.id} className="card1">
              <h3>{test.title}</h3>
              <p>{test.description}</p>
              <button onClick={() => handleSelectTest(test.id)}>
                Iniciar Test
              </button>
            </div>
          ))}
        </div>
      ) : (
        <TestEvaluator
          test={selectedTest}
          onBack={() => setSelectedTest(null)}
        />
      )}
    </div>
  );
};

export default TestsList;
